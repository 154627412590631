import React from 'react';
import { Grid } from '@mui/material';
import main from './image/mob.gif'
import welcome from './image/welcome.png'
import aztec from './image/azte.png'

const SplineIframe = () => {
  return (
    <div style={{ width: '100%', height: '100vh' }}>
       <Grid container>
                 
                 <Grid item md={6} lg={6} xs={12} sm={12} className='centeritall'>
                  <br/><br/><br/><br/>
                 <img src={   welcome} style={{width:"100%"}} /> <br/> <br/>
                 <img src={  aztec} style={{width:"100%"}} /> <br/> <br/> <br/> <br/> <br/> <br/> <br/>
                 <a href="welcome"> <p className='pixel-buttond linear-wiped'>ENTER</p></a>

</Grid>
<Grid item md={6} lg={6} xs={12} sm={12} className='centeritall'>
<br/> <br/> <br/><br/>
                          <img src={ main} className='main_image'/>

                 </Grid>
       </Grid>
     
      
    </div>
  );
};

export default SplineIframe;
