import React from 'react'

function Disclaimer() {
  return (
    <div className='container'>
        
        <section>  <br/>  <br/>  <br/>   
      <h2 className='headtext_footer_pages'>Aztec Crypto Terms and Conditions</h2>
      <br/>
      <p style={{textAlign:"center" , fontSize:"13px"}}>Welcome to Aztec Crypto! By accessing our website and using our services, you agree to comply with and be bound by the following terms and conditions.</p>
      <br/>
      <h2 className='headtext_footer_pages'>1. Acceptance of Terms</h2>
      <br/>
      <p style={{textAlign:"center" , fontSize:"13px"}}>By using Aztec Crypto's services, you accept and agree to be bound by these Terms and Conditions.</p>
      <br/>
      <h2 className='headtext_footer_pages'>2. Eligibility</h2>
      <br/> 
      <p style={{textAlign:"center" , fontSize:"13px"}}>You must be of legal age in your jurisdiction to use our services. It is your responsibility to ensure compliance with local laws and regulations.</p>
 
      <br/>
      <h2 className='headtext_footer_pages'>3. Intellectual Property</h2>
      <br/> 
      <p style={{textAlign:"center" , fontSize:"13px"}}>All content on our website, including but not limited to text, graphics, logos, icons, and images, is the property of Aztec Crypto and is protected by copyright laws.</p>
      <br/>
      <h2 className='headtext_footer_pages'>4. Prohibited Activities</h2>
      <br/> 
      <p style={{textAlign:"center" , fontSize:"13px"}}>Users shall not engage in any unlawful or unauthorized use of our services, including but not limited to spreading harmful software or engaging in fraudulent activities.</p>
      <br/> 
      <h2 className='headtext_footer_pages'>5. Disclaimer of Liability</h2>
      <br/> 
      <p style={{textAlign:"center" , fontSize:"13px"}}>Aztec Crypto shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages, including but not limited to, damages for loss of profits, goodwill, data, or other intangible loses.</p>
      <br/> 
<div className='centeritall'>
<a href='welcome'>
<button class="cybr-btn">
  Back<span aria-hidden></span>
  <span aria-hidden class="cybr-btn__glitch">Aztec</span>


</button>

</a>

<br/>    <br/> 
</div>
   


      
    </section>
        
        
        
        </div>
  )
}

export default Disclaimer