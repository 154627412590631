import React from "react";
import { useMediaQuery } from "@mui/material";

import Home from "../component/Home/Home";
import HomeDesk from "../component/Home/HomeDesk";
function YourComponent() {
  const desktopBreakpoint = useMediaQuery("(min-width: 1450px)");
  const tabletBreakpoint = useMediaQuery("(max-width: 1449px) and (min-width: 1100px)");
  const mobileBreakpoint = useMediaQuery("(max-width: 1099px)");

  return (
    <div>
      {desktopBreakpoint && (
        <div>
          {/* Content for desktop view */}
      <HomeDesk/>
        </div>
      )}
      {tabletBreakpoint && (
        <div>
           <HomeDesk/>
          
        </div>
      )}
      {mobileBreakpoint && (
        <div>
          <Home/>

        </div>
      )}
      
    </div>
  );
}

export default YourComponent;
