import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import './Navbars.css'
import logo from './Logo.gif'


// Updated pages array with corresponding href links
const pages = [
  { name: 'HOME', link: '/welcome' },
  { name: 'INFO', link: '/info' },
  { name: 'Resource', link: '/resources' },
  { name: 'Contact', link:'/Contact' },




];

const settings = [
  { label: 'Buy/Sell', link: 'https://quickswap.exchange/#/swap?inputCurrency=ETH&outputCurrency=0xe5087395862a208071A7909687a6c4Fe30458F1e&swapIndex=1' },
  { label: 'Staking', link: 'https://staking.azteccrypto.xyz/' },

  { label: 'Game', link: 'https://games.azteccrypto.xyz/' }
];


function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: '#000' }}> {/* Set the background color */}
    <br/>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
     
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/welcome"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <img src={logo} style={{width:"120px"}}/>
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                 <a
  key={page.name}
  href={page.link}
  onClick={handleCloseNavMenu}
  style={{ textDecoration: 'none', color: 'inherit', margin: '0 8px' }}
>
  {page.name}
</a>
                </MenuItem>
                
              ))}
            </Menu>
          </Box>
        
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
             <img src={logo} style={{width:"120px"}}/>
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex'  } }} style={{justifyContent:"center"}}>
            {pages.map((page) => (
              <Button
                key={page.name}
                component="a"
                href={page.link}
                onClick={handleCloseNavMenu}
                sx={{ my: 3,  display: 'block' }}
                className='hoveranimationoftopnav'
              >
                {page.name}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Explore">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }} style={{color:"#fff"}}>
            Start
              </IconButton>
            </Tooltip>
            <Menu
  sx={{ mt: '45px' }}
  id="menu-appbar"
  anchorEl={anchorElUser}
  anchorOrigin={{
    vertical: 'top',
    horizontal: 'right',
  }}
  keepMounted
  transformOrigin={{
    vertical: 'top',
    horizontal: 'right',
  }}
  open={Boolean(anchorElUser)}
  onClose={handleCloseUserMenu}
>
  {settings.map((setting) => (
    <MenuItem key={setting.label} onClick={handleCloseUserMenu}>
      <a href={setting.link} style={{ textDecoration: 'none', color: 'inherit' }}>
        <Typography textAlign="center">{setting.label}</Typography>
      </a>
    </MenuItem>
  ))}
</Menu>

          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
