import React from "react";
import { useMediaQuery } from "@mui/material";
import Navbars from "../Navbar/Navbars";
import Welcome from "../component/Staking";
import Footer from "../Footer/Footer";
import Divider from '@mui/material/Divider';



function YourComponent() {
  const desktopBreakpoint = useMediaQuery("(min-width: 1450px)");
  const tabletBreakpoint = useMediaQuery("(max-width: 1449px) and (min-width: 1100px)");
  const mobileBreakpoint = useMediaQuery("(max-width: 1099px)");

  return (
    <div>
      {desktopBreakpoint && (
        <div>
          {/* Content for desktop view */}
     
      <Welcome/>
      
        </div>
      )}
      {tabletBreakpoint && (
        <div>
         
           <Welcome/> 
        </div>
      )}
      {mobileBreakpoint && (
        <div>
           
           <Welcome/> 
        </div>
      )}
      
    </div>
  );
}

export default YourComponent;
