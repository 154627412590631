import React, { useState } from 'react';
import { Grid } from '@mui/material';
import Logo from './Logo.gif';
import { NavLink } from 'react-router-dom'; // Assuming you are using React Router

function Footer() {
  const [activeLink, setActiveLink] = useState(''); // State to track active link

  // Function to handle link click and update active link
  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  return (
    <div className='container'>
      <Grid container spacing={2}>
        <Grid item md={6} lg={6} xs={12} sm={12}>
          <br />
          <br />
          <ul style={{ textAlign: 'center' }}>
            <li>
              <NavLink
                to='/welcome' // Update with your route path
                className={`link ${activeLink === 'welcome' ? 'active' : ''}`}
                onClick={() => handleLinkClick('welcome')}
              >
                Home
              </NavLink>
            </li>
           
            {/* Add similar NavLink components for other links */}
            <li>
              <NavLink
                to='/info' // Update with your route path
                className={`link ${activeLink === 'info' ? 'active' : ''}`}
                onClick={() => handleLinkClick('info')}
              >
                INFO
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/resources' // Update with your route path
                className={`link ${activeLink === 'resources' ? 'active' : ''}`}
                onClick={() => handleLinkClick('resources')}
              >
         Resource
              </NavLink>
            </li>
          
           
            <li>
              <NavLink
                to='https://quickswap.exchange/#/swap?inputCurrency=ETH&outputCurrency=0xe5087395862a208071A7909687a6c4Fe30458F1e&swapIndex=1' // Update with your route path
                style={{textDecoration:"none" , color :"#fff"}}
                onClick={() => handleLinkClick('buy-sell')}
              >
             Buy/Sell
              </NavLink>
            </li>
            <li>
              <NavLink
                to='https://staking.azteccrypto.xyz/' // Update with your route path
                style={{textDecoration:"none" , color :"#fff"}}
                onClick={() => handleLinkClick('buy-sell')}
              >
            Staking
              </NavLink>
            </li>
            <li>
              <NavLink
                to='https://games.azteccrypto.xyz/' // Update with your route path
                className={`link ${activeLink === 'https://games.azteccrypto.xyz/' ? 'active' : ''}`}
                onClick={() => handleLinkClick('https://games.azteccrypto.xyz/')}
              >
               Game
              </NavLink>
            </li>

            <li>
              <NavLink
                to='/Whitepaper' // Update with your route path
                className={`link ${activeLink === ' Whitepaper' ? 'active' : ''}`}
                onClick={() => handleLinkClick('Whitepaper')}
              >
               Whitepaper
              </NavLink>
            </li>
            {/* Add similar NavLink components for other links */}
          </ul>
        </Grid>

        <Grid item md={6} lg={6} xs={12} sm={12}>
          <div className='about-logo'>
            <img src={Logo} alt='Logo' />
          </div>
        </Grid>
      </Grid>




      <Grid container spacing={2}>
       

        <Grid item md={12} lg={12} xs={12} sm={12}>
        <div class="footer-bottom">
            Copyright © 2024 <span class="highlight">Aztec Crypto</span>  | <a href=" Term_Condition" target="_blank" class="highlight">Terms &amp; Conditions</a> | <a href="PrivacyPolicy" target="_blank" class="highlight">Privacy Policy</a> | <a href="disclaimer" target="_blank" class="highlight">Disclaimer</a>
        </div>
        </Grid>
      </Grid>
      <br/>
    </div>
  );
}

export default Footer;
