import React from 'react'

import { Grid } from '@mui/material'

function Info() {
  return (
    <div className='container'>
 
<br/>   <br/>

<h1 className="main-title_infot">White Paper</h1>
<br/>
<Grid container spacing={2}>
           <Grid item md={12} lg={12} xs={12} sm={12} className='centeritall'>

        
           <br/><br/>
<h2 className="info-title">1. Introduction</h2>
<p className='info-title_under_white'>
<span className="info-title">a.</span>Aztec Crypto Currency was created as an alternative to traditional currencies
and financial systems. We use decentralized technology to enable secure and
transparent transactions without the need for intermediaries such as banks.
The purpose of Aztec Crypto Currency is to provide more control over our
money in the community as well as fight inflation and provide faster, cheaper
and more secure transactions, without the need for third parties. The currency
was also designed to provide users with greater privacy, anonymity and seeks
to solve specific problems such as financial inclusion and more efficient crossborder payments.
</p>
<br/><br/>
<p className='info-title_under_white'>
<span className="info-title">b.</span>  Aztec Crypto Currency can fight the devaluing of the US Dollar in a few ways.
One way is that our currency has mechanisms built into its design that
encourage long-term holding and discourage speculative trading, which limits
some of the volatility seen in traditional markets. Additionally, our currency
will have a fixed supply, which means that there cannot be inflation by any
central authority, providing users with certainty about the total supply of the
currency. This could be seen as a hedge against inflation or deflation of fiat
currency such as the USD. Lastly, Aztec Crypto Currency aims to offer alternative
 stores of value, which could potentially compete with traditional assets such as
 gold or real estate, providing additional diversification in uncertain economic
climates.

</p>
           </Grid>
           <Grid item md={12} lg={12} xs={12} sm={12} className='centeritall'>

        
<br/><br/>
<h2 className="info-title">2. Background</h2>
<p className='info-title_under_white'>
Inflation is the rate at which the general price level of goods and services in an
economy increases over time. When inflation occurs, the purchasing power of
money decreases as the cost of goods and services rises. Inflation is generally
considered bad for the economy for several reasons:
</p>
<br/><br/>
<p className='info-title_under_white'>
<span className="info-title">a.</span> Reduced purchasing power

</p>
<p className='info-title_under_white'>
<span className="info-title">b.</span> Reduced Investment

</p>
<p className='info-title_under_white'>
<span className="info-title">c.</span> Higher costs of borrowing

</p>
<p className='info-title_under_white'>
<span className="info-title">d.</span> Economic instability

</p>

<p className='info-title_under_white'>
<span className="info-title">e.</span> Social inequality

</p>

<p className='info-title_under_white'>
As costs of goods and services increases, the income of those that do not
generate income through assets purchasing power decreases, leading to more
economic inequality. Overall, inflation can diminish economic growth and
stability for the Rio Grande Valleys, impeding social welfare and the ability of
our individuals and our businesses to plan for the long term. Central banks have
 developed strategies to tackle inflation and maintain price stability, often
aided by governments, but have sadly failed.
</p>
</Grid>

<Grid item md={12} lg={12} xs={12} sm={12} className='centeritall'>

        
<br/><br/>
<h2 className="info-title">3. Solution</h2>
<p className='info-title_under_white'>
As part of our strategy to enhance the value of the token, a portion of profits
generated by the group are directed towards increasing the liquidity of our
coin. By doing so, we ensure a stable market and an increase in the value of our
token. We understand that a solid backing is crucial to building and maintaining
investor confidence. Our team is dedicated to creating a stable and secure
market. By combining our unwavering dedication to provide the best service
possible to our investors with the experience and leadership that our crypto
group provides, we are confident in our ability to deliver results. We welcome
all of our current and potential investors to join us as we continue to grow and
enhance our project.
</p>
<p className='info-title_under_white'>
Staking will be implemented in our token to promote long-term investing and
incentivize holders to buy tokens. Our crypto games are specifically tailored to
our tokens, making users buy tokens to play. By implementing these
mechanisms, the Aztec Crypto Currency incentivizes long-term holding, fund
community development and charity projects and maintain a stable value, with
possible long-term growth. This can be a good thing for community investors as
it would establish Aztec Crypto Currency as a coin that is committed to fostering
 growth in the community.
</p>
<br/><br/>

</Grid>

<Grid item md={12} lg={12} xs={12} sm={12} className='centeritall'>

        
<br/><br/>
<h2 className="info-title">4. Staking Rewards</h2>
<p className='info-title_under_white'>
The rewards of staking are as follows:<br/>
For staking for a 3-month period user will be rewarded with 2% of investment.<br/>
For staking for a 6-month period user will be rewarded with 5% of investment.<br/>
For staking for a 12-month period user will be rewarded with 11% of
investment.<br/>
</p>

<br/><br/>

</Grid>


<Grid item md={12} lg={12} xs={12} sm={12} className='centeritall'>

        
<br/><br/>
<h2 className="info-title">5. Use cases</h2>
<p className='info-title_under_white'>
Aztec Crypto Currency uses blockchain technology to create a ledger of
transactions that are recorded in a decentralized, secure manner. The
blockchain is a distributed ledger that is composed of many nodes or computers
 that communicate with one another. The blockchain allows every transaction
made on it to be separately verified to eliminate fraud while maintaining
privacy. When a new transaction is made with a cryptocurrency, it is entered as
a new block that is added to the chain, creating an immutable ledger of all
transactions made on that blockchain. Each block contains a set of unique
transactions recorded and verified by some of the nodes in the network to
ensure the integrity of the network. The blockchain ledger’s decentralized
nature means that it is not susceptible to fraud or manipulation since every
transaction recorded on the blockchain is verified by the consensus of the
network’s nodes. Cryptocurrency ledgers can also be used to record other
information, such as smart contracts, digital identity information, and
ownership information of asset-backed tokens. This information is also stored
in the blocks to ensure its accuracy, transparency and secure maintenance on
the blockchain. Overall, the use of Aztec Crypto Currency as a ledger offers our
community significant benefits such as decentralized control, secure processing
 and immutability, making it an excellent option for recording transactions
securely and reliably while preserving transaction privacy.
</p>
<p className='info-title_under_white'>
Aztec Crypto Currency eventually will be used for commerce in several ways.
Here are a few examples: a. Online payments b. International transactions c.
Micropayments d. Point of Sale transactions
In conclusion Aztec Crypto Currencies can offer faster, cheaper, and more
secure transactions, making it an attractive alternative to traditional payment
options for both merchants and consumers.
</p>
<br/><br/>

</Grid>



<Grid item md={12} lg={12} xs={12} sm={12} className='centeritall'>

        
<br/><br/>
<h2 className="info-title">6. Transaction Fees</h2>
<p className='info-title_under_white'>
A 2% tax mechanism is written in Aztec Crypto Currency code. It works in the
following way: When someone purchases/sells the cryptocurrency, 1% of the
tokens are automatically sent to our crypto wallet, and the other 1% is sent to
our staking smart contract and games.
</p>

<br/><br/>

</Grid>


<Grid item md={12} lg={12} xs={12} sm={12} className='centeritall'>

        
<br/><br/>
<h2 className="info-title">7. Conclusion</h2>
<p className='info-title_under_white'>
Overall, Aztec Crypto Currency offers unique features that cater to the specific
needs of the Rio Grande Valley, focusing on community development,
incentivizes our citizens for more long-term holding, offering greater scalability
 that helps to reduce the impact of volatility, greater control over our own
monetary policies and transparency, reducing the risk of fraud and corruption.
Therefore, Aztec Crypto Currency is a better option for the community’s needs
than any other cryptocurrency.
</p>

<br/><br/>

</Grid>
</Grid>

<br/>   <br/> <br/>


            <br/><br/><br/>




    </div>
  )
}

export default Info