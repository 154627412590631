import React, { useState } from 'react';
import { Grid } from '@mui/material'
import Slider from './Slider';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { FaFacebookF } from "react-icons/fa6";
import { FaDiscord } from "react-icons/fa";
import Twitter from '@mui/icons-material/Twitter';
import CryptoNews from './CryptoNews'
function Resource() {
       const [copiedAddress1, setCopiedAddress1] = useState(false);
       const [copiedAddress2, setCopiedAddress2] = useState(false);
     
       const handleCopyClick = (textToCopy, setAddressCopied) => {
         navigator.clipboard.writeText(textToCopy);
         setAddressCopied(true);
     
         // Reset the 'Copied' message after a short delay
         setTimeout(() => {
           setAddressCopied(false);
         }, 1500);
       };
     
       const address1ToCopy = '0xe5087395862a208071A7909687a6c4Fe30458F1e';
       const address2ToCopy = '0xA13bfC3C8A1a17549DCa6ff896501Fc5196515f0';
  return (
    <div className='container'>

<br/><br/>
<h2 className="tophead_info">Before we dive in, Lets learn the</h2>
<h1 className="main-title_info">Resource</h1>
<br/>
               <h2 className="info-title" style={{textAlign:"center"}}>Token Address </h2>

   <p
           className='info-title_underc'
           style={{ textAlign: 'center', cursor: 'pointer' }}
           onClick={() => handleCopyClick(address1ToCopy, setCopiedAddress1)}
         >
           {copiedAddress1 ? 'Copied!' : address1ToCopy}
         </p>

   
<Slider/>

<div className='container'>
      <div >    

      <h2 className="tophead_info">      What’s going on with us</h2>
      <br/><br/> 
      <br/> 
        <div className='centeritall'>
           
        <a href="https://www.youtube.com/@AztecCrypto?si=nKF2LpVlS6CZXQ00" target="_blank" className="youtube-icon">
            
            <YouTubeIcon  style={{fontSize:"35px" , position:"relative"  , bottom:"17px" , right:"19px"   }} />
            

            </a>
            <a href="https://www.facebook.com/profile.php?id=100088880297462&mibextid=LQQJ4d" target="_blank" className="youtube-icon">
            
            <FaFacebookF  style={{fontSize:"35px" , position:"relative"  , bottom:"17px" , right:"19px"   }} />
            

            </a>
          
            <a href="https://twitter.com/azteccryptoxyz?s=21&t=D9ZKCU4MRSaVbCAUgv5MWg" target="_blank" className="youtube-icon">
            
            <Twitter style={{fontSize:"35px" , position:"relative"  , bottom:"17px" , right:"19px"   }} />
            
            </a>
        
                
        </div><br/>


    
    <br/>   <br/>
    <h2 className="tophead_info">What's the market saying about</h2>
<h1 className="main-title_info">Aztec Crypto</h1>

<br/><br/><br/><br/>

      <iframe
        title="QuickSwap"
        src="https://top100token.com/address/0xe5087395862a208071a7909687a6c4fe30458f1e"
        width="100%"
        height="600px"
        frameBorder="0"
        allowFullScreen
      ></iframe>
      <br/><br/>
    </div>
      

    <br/>  <br/>  
<CryptoNews/>
<br/><br/> 

<br/>  
</div>



{/*<Grid container spacing={2}>
<Grid item lg={12} md={12} xs={12} sm={12} >
       <br/><br/>
<h2 className="info-title">Aztec Cryptocurrency Explainer Video</h2>
<br/>
       <div className="video-container">
                <iframe src="https://www.youtube.com/embed/DU-enErB-C4" frameborder="0" allowfullscreen=""></iframe>
            </div>


       </Grid>

       <Grid item lg={12} md={12} xs={12} sm={12} >
       <br/>
<h2 className="info-title">Investment Strategy Video </h2>
<br/>


       <div className="video-container">
                <iframe src="https://www.youtube.com/embed/k8nd7fvNzPs" frameborder="0" allowfullscreen=""></iframe>
            </div>


       </Grid>

       <Grid item lg={12} md={12} xs={12} sm={12} >
       <br/>
<h2 className="info-title">How To Buy</h2>
<br/>
       <div className="video-container">
                <iframe src="https://www.youtube.com/embed/iqSCYqp3ClA" frameborder="0" allowfullscreen=""></iframe>
            </div>
            <br/>
            
            <br/>
<p
        className='info-title_underc'
        style={{ textAlign: 'center', cursor: 'pointer' }}
     
      >
  
    <br/>
1. Go to buy/ sell page
<br/>
2. Sign Disclaimers<br/> 
3. Connect wallet<br/>
4. Input desired trade amount<br/>
5. Change the Max Slippage to account for liquidity.<br/>
6. Press Confirm
      </p>
<p
        className='info-title_underc'
        style={{ textAlign: 'center', cursor: 'pointer' }}
     
      >
     Note: It is important to adjust the max slippage before initiating the trade, please adjust the max slippage to around 30% if need be.

      </p>
  

<p class="info-title_underc" style={{textAlign:"center"}}>      MetaMask</p>
<p class="info-title_underc" style={{textAlign:"center" , color:"#fff"}}>       <a href='https://metamask.io/' class="info-title_underc" style={{textAlign:"center" , color:"#fff"}} target='_blank'>https://metamask.io/</a></p>


<div className='container'>
      <p class="info-title_underc" style={{textAlign:"center"}}>      Coinbase</p>
      <p class="info-title_underc" style={{textAlign:"center" , color:"#fff"}}>       <a href='https://www.coinbase.com/price/polygon?utm_campaign=rt_i_m_w_m_acq_ugc_soc_0_asset&utm_source=ugc&utm_platform=iOS' class="info-title_underc" style={{textAlign:"center" , color:"#fff"}} target='_blank'>https://www.coinbase.com/price/polygon</a></p> </div>


       </Grid>

       <Grid item lg={12} md={12} xs={12} sm={12} >
       <br/><br/>
<h2 className="info-title">How To Sell </h2>
<br/>
       <div className="video-container">
                <iframe src="https://www.youtube.com/embed/vK9khk25ST4" frameborder="0" allowfullscreen=""></iframe>
            </div>

            <br/>
<p
        className='info-title_underc'
        style={{ textAlign: 'center', cursor: 'pointer' }}
     
      >
    Note: When selling tokens you must manually adjust the spending cap. The process is to adjust the spending cap is as follows;
    <br/>
1. Hit approve RGV
<br/>
2. Hit Max Spending Cap <br/> 
3. Hit Next <br/>
4. Hit Confirm

      </p>
       </Grid>

       <Grid item lg={12} md={12} xs={12} sm={12} >
    
<h2 className="info-title">How To Import Tokens </h2>
<br/>
       <div className="video-container">
                <iframe src="https://www.youtube.com/embed/-_JbQpMfd_w" frameborder="0" allowfullscreen=""></iframe>
            </div>
            <br/>
<p
        className='info-title_underc'
        style={{ textAlign: 'center', cursor: 'pointer' }}
     
      >
      Note: When importing your tokens, make sure that you switch to the polygon network.

      </p>

       </Grid>

       <Grid item lg={12} md={12} xs={12} sm={12} >
   

<h2 className="info-title">How To Stake Tokens </h2>

       <div className="video-container">
                <iframe src="https://www.youtube.com/embed/b-ewac6KVH0" frameborder="0" allowfullscreen=""></iframe>
            </div>
            <br/>
            <h2 className="info-title" style={{textAlign:"center"}}>Token Address </h2>
<br/>
<p
        className='info-title_underc'
        style={{ textAlign: 'center', cursor: 'pointer' }}
        onClick={() => handleCopyClick(address1ToCopy, setCopiedAddress1)}
      >
        {copiedAddress1 ? 'Copied!' : address1ToCopy}
      </p>
<br/>
<h2 className="info-title" style={{textAlign:"center"}}>Staking Address </h2>
<br/>
<p
        className='info-title_underc'
        style={{ textAlign: 'center', cursor: 'pointer' }}
        onClick={() => handleCopyClick(address2ToCopy, setCopiedAddress2)}
      >
        {copiedAddress2 ? 'Copied!' : address2ToCopy}
      </p>
    




       </Grid>

   











      
</Grid> */}










<br/>
    </div>
  )
}

export default Resource