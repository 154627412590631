import React from "react";
import { useMediaQuery } from "@mui/material";

import Blog from "../component/News/Blog1";
function YourComponent() {
  const desktopBreakpoint = useMediaQuery("(min-width: 1450px)");
  const tabletBreakpoint = useMediaQuery("(max-width: 1449px) and (min-width: 1100px)");
  const mobileBreakpoint = useMediaQuery("(max-width: 1099px)");

  return (
    <div>
      {desktopBreakpoint && (
        <div>
          {/* Content for desktop view */}
      <Blog/>
        </div>
      )}
      {tabletBreakpoint && (
        <div>
      <Blog/>
          
        </div>
      )}
      {mobileBreakpoint && (
        <div>
 <Blog/>

        </div>
      )}
      
    </div>
  );
}

export default YourComponent;
