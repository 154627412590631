import React from 'react'

import { Grid } from '@mui/material'
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import { TbWorld } from "react-icons/tb";
import {
    MDBCard,
    MDBCardTitle,
    MDBCardText,
    MDBCardBody,
    MDBCardImage,
    MDBRow,
    MDBCol
  } from 'mdb-react-ui-kit';
import Telegram from '@mui/icons-material/Telegram';


import Team1 from './Team1.png'
import Team2 from './Team2.png'
import Team3 from './sab.jpg'
import Logo from './loco.png'
const emailAddress = 'houselevelingtyler@icloud.com';

const handleEmailClick = () => {
  window.location.href = `mailto:${emailAddress}`;
};


const emailAddresst = 'noormuhammadteche@gmail.com';

const handleEmailClickt = () => {
  window.location.href = `mailto:${emailAddresst}`;
};



function Info() {
  return (
    <div className='container'> <br/>

   <br/> <br/>
<br/> 
<h2 className="tophead_info">Meet the</h2>
<h1 className="main-title_info">Team</h1>
<br/>

<br/><br/>
<Grid container spacing={3}>
      <Grid item md={6} lg={6} xs={12} sm={12} className='centeritallt'>


      <div class="myCard">
        <div class="innerCard">
            <div class="frontSide">
                <p class="title">
                <img src={Team1} style={{width:"100%" , height:"100%" , borderRadius:"20px"}}/>

                </p>
           
                <p style={{color:"#ff0099"}}>Tyler<br/>
                Marketing Manager</p>
             
            </div>
            <div class="backSide">
                <p class="title">



                </p>
             
                <p style={{fontSize:"10px" , color:"#fff"}} className='info-title_under'>
   
      </p>
            </div>
        </div>
    </div>
      </Grid>

      <Grid item md={6} lg={6} xs={12} sm={12} className='centeritallt'>


<div class="myCard">
  <div class="innerCard">
      <div class="frontSide">
          <p class="title">
          <img src={Team2} style={{width:"100%" , height:"100%" , borderRadius:"20px"}}/>

          </p>
     
          <p style={{color:"#ff0099"}}>Noor <br/>
Senior Dev</p>
       
      </div>
      <div class="backSide">
          <p class="title">&nbsp;&nbsp;
<a href='' style={{color:"#fff"}} target='_blank'>

</a>
&nbsp;&nbsp;

<a href='' style={{color:"#fff"}} target='_blank'>

</a>

&nbsp;&nbsp;


          </p>
       
          <p style={{fontSize:"10px" , color:"#fff"}} className='info-title_under'>


</p>
      </div>
  </div>
</div>
</Grid>







<Grid item md={12} lg={12} xs={12} sm={12} className='centeritallt'>
<br/><br/>

<div class="myCard">
  <div class="innerCard">
      <div class="frontSide">
          <p class="title">
          <img src={Team3} style={{width:"100%" , height:"100%" , borderRadius:"20px"}}/>

          </p>
     
          <p style={{color:"#ff0099"}}>Sabir<br/>
          Crypto Dev </p>
       
      </div>
      <div class="backSide">
          <p class="title">&nbsp;&nbsp;
<a href='' style={{color:"#fff"}} target='_blank'>

</a>
&nbsp;&nbsp;

<a href='' style={{color:"#fff"}} target='_blank'>

</a>

&nbsp;&nbsp;


          </p>
       
          <p style={{fontSize:"10px" , color:"#fff"}} className='info-title_under'>


</p>
      </div>
  </div>
</div>
</Grid>
</Grid>






























































































<br/>   <br/><br/>
<h2 className="tophead_info">Meet the</h2>
<h1 className="main-title_info">SPONSOR</h1>
<br/>
<br/>  


            <br/><br/>
         <Grid container>
                     <Grid item md={12} xs={12} lg={12} sm={12} className='centeritallt'>


                     <MDBCard style={{ maxWidth: '100%' }} className='back_of_sponser'>
      <MDBRow className='g-0'>
        <MDBCol md='4'>
          <MDBCardImage src={Logo} alt='...' fluid />
        </MDBCol>
        <MDBCol md='8'>
          <MDBCardBody> <br/>
            <MDBCardTitle>Aztec <br/>
House Leveling & Remodeling</MDBCardTitle> <br/> <br/>
            <p>
            Aztec House Leveling and Remodeling offers foundation repair, floor &amp; concrete leveling, remodeling, roofing, and plumbing services to the South Texas residential and commercial properties.
            </p>
            <MDBCardText>
              <small className='text-muted'>

              <a href='https://aztecahomeleveling.com/' style={{color:"#fff"  }} target='_blank'>
<TbWorld style={{fontSize:"25px" }} />
</a>

&nbsp;&nbsp;

<a href='https://twitter.com/aztechl' style={{color:"#fff"}} target='_blank'>
<TwitterIcon/>
</a>
&nbsp;&nbsp;

<a href='https://www.facebook.com/aztechl' style={{color:"#fff"}} target='_blank'>
<FacebookIcon/>
</a>



              </small>
            </MDBCardText>
          </MDBCardBody>
        </MDBCol>
      </MDBRow>
    </MDBCard>
                     </Grid>

         </Grid>

    <br/>
<br/>  


         

    </div>
  )
}

export default Info