import React, { useState, useEffect } from 'react';
import axios from 'axios';

const MaticPrice = () => {
  const [maticPrice, setMaticPrice] = useState(null);

  useEffect(() => {
    const fetchMaticPrice = async () => {
      try {
        const response = await axios.get(
          'https://api.coingecko.com/api/v3/simple/price?ids=matic-network&vs_currencies=usd'
        );

        if (response.status === 200) {
          const priceData = response.data['matic-network'].usd;
          setMaticPrice(priceData);
        }
      } catch (error) {
        console.error('Error fetching Matic price: ', error);
      }
    };

    const interval = setInterval(() => {
      fetchMaticPrice();
    }, 5000); // Fetch data every 5 seconds (adjust as needed)

    return () => clearInterval(interval); // Cleanup interval

  }, []);

  return (
    <div>
      <h2 style={{textAlign:"center"}}>Matic (Polygon) </h2>
      {maticPrice ? <p style={{textAlign:"center"}}>Current Price: ${maticPrice}</p> : <p style={{textAlign:"center"}}>Loading...</p>}
    </div>
  );
};

export default MaticPrice;
