import React from 'react'

function Staking() {
  return (
    <div >
  <iframe
        title="QuickSwap"
        src="https://master.d2s5sgzu9imodv.amplifyapp.com/"
        width="100%"
        height="1000px"
        frameBorder="0"
        allowFullScreen
      ></iframe>



    </div>
  )
}

export default Staking