import React from 'react'
import { Grid } from '@mui/material'
import YouTubeIcon from '@mui/icons-material/YouTube';
import { FaFacebookF } from "react-icons/fa6";
import { FaDiscord } from "react-icons/fa";
import Twitter from '@mui/icons-material/Twitter';
import CryptoNews from './CryptoNews'
function News() {
  return (
    <div className='container'>
      <div >     <br/><br/> 

      <h2 className="tophead_info">      What’s going on with us</h2>
      <br/><br/> 
      <br/> 
        <div className='centeritall'>
           
        <a href="https://www.youtube.com/@AztecCrypto?si=nKF2LpVlS6CZXQ00" target="_blank" className="youtube-icon">
            
            <YouTubeIcon  style={{fontSize:"35px" , position:"relative"  , bottom:"17px" , right:"19px"   }} />
            

            </a>
            <a href="https://www.facebook.com/profile.php?id=100088880297462&mibextid=LQQJ4d" target="_blank" className="youtube-icon">
            
            <FaFacebookF  style={{fontSize:"35px" , position:"relative"  , bottom:"17px" , right:"19px"   }} />
            

            </a>
          
            <a href="https://twitter.com/azteccryptoxyz?s=21&t=D9ZKCU4MRSaVbCAUgv5MWg" target="_blank" className="youtube-icon">
            
            <Twitter style={{fontSize:"35px" , position:"relative"  , bottom:"17px" , right:"19px"   }} />
            
            </a>
        
                
        </div><br/>


    
    <br/>   <br/>
    <h2 className="tophead_info">What's the market saying about</h2>
<h1 className="main-title_info">Aztec Crypto</h1>

<br/><br/><br/><br/>

      <iframe
        title="QuickSwap"
        src="https://top100token.com/address/0xe5087395862a208071a7909687a6c4fe30458f1e"
        width="100%"
        height="600px"
        frameBorder="0"
        allowFullScreen
      ></iframe>
      <br/><br/>
    </div>
      

    <br/>  <br/>  
<CryptoNews/>
<br/><br/> 

<br/>  
</div>
  )
}

export default News