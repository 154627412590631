import React from 'react'
import Faqs from './gifla.gif'
import Faq2 from './icon/card1.gif'
import Faq3 from './icon/card2.gif'

import Faq1 from './icon/card3.gif'

function Faq() {
  return (
    <div className='centeritall'>
    <br/>    <br/>    <br/>    <br/>
    <h2 className="about-aztec-title"> Faq</h2>
        <br/>
    
        <div className="faq-item">
        <div data-aos="fade-right">  <img src={Faq1} alt="Description of GIF" className='faqgifzp'/></div>
           
                <h3 className="faq-subheading">HOW DO I BUY Aztec TOKENS?</h3><br/>
                <p className="faq-text">
                The first thing you will need is a crypto wallet, preferably MetaMask wallet. Secondly 
you will need to purchase Polygon from a major exchange, such as Coinbase. (Note: When buying polygon, it is
 important to keep a little extra in your crypto wallet, as you will need it to pay for "Gas Fee's". Don't worry, 
our tokens were specifically designed on the polygon blockchain because of the low fees. Most time gas is 
only a couple of cents, even when transferring thousands of dollars.) Lastly, once you have polygon in your 
crypto wallet, you can then come here to our site and connect directly to our liquidity pool which is located on 
the "Buy/Sell" page. If you are having trouble please locate our help page.
                  
                  
                  </p><br/>


                <a href='/info'>  <button className="faq-button">Learn More</button></a>
              
            </div>
     


            <div className="faq-item">
            <div data-aos="fade-right">  <img src={Faq2} alt="Description of GIF"  className='faqgifzp'/></div>
          
                <h3 className="faq-subheading">WHAT ARE THE Games?</h3><br/>
                <p className="faq-text">
            AT AztecCrypto.XYZ, WE OFFER EXCITING AND REWARDING PRIZES FOR OUR PLAYERS. OUR GAMES FEATURE JACKPOTS IN THE FORM OF AZTEC
             CRYPTOCURRENCY TOKENS. WHEN YOU WIN, YOU’LL RECEIVE ADDITIONAL
              TOKENS THAT YOU CAN CHOOSE TO SELL FOR REAL-WORLD VALUE. 
            WITH OUR THRILLING PRIZES, YOU CAN ENJOY THE EXCITEMENT OF THE GAME AND HAVE A CHANCE TO WIN VALUABLE REWARDS. START PLAYING TODAY AND TRY YOUR LUCK AT WINNING BIG AT AztecCrypto.XYZ!
                    
                    </p><br/>


                <a href='/info'>  <button className="faq-button">Learn More</button></a>
              
            </div>


            <div className="faq-item">
            <div data-aos="fade-right"> <img src={Faq3} alt="Description of GIF"  className='faqgifzp'/></div>
              
                <h3 className="faq-subheading">Why play Aztec Crypto Games?</h3><br/>
                <p className="faq-text">
                We offer traditional and nontraditional style gaming, where our games are set apart from other online gaming websites. There is seamless token integration ensuring every transaction is automatic and secure. In a landscape where Aztec Tokens have a symbiotic relationship with the games we offer. AztecCryot.xyz stands as a pioneer in shaping a new era of interactive entertainment. Join us in exploring the future of Cryptocurrencies and unlock a world of potential.
                    </p><br/>


                <a href='/info'>  <button className="faq-button">Learn More</button></a>
              
            </div>
         
        
      
           

       </div>
  )
}

export default Faq