import React from 'react';
import { useSwiper } from 'swiper/react';
import { Grid } from '@mui/material';
const SwiperNavButtons = () => {
  const swiper = useSwiper();

  return (
    <div className="swiper-nav-btns">
        <Grid container spacing={2}>
<Grid item md={6} lg={6} xs={6} sm={6} className='centeritall'>  <button onClick={() => swiper.slidePrev()}>Prev</button></Grid>
<Grid item md={6} lg={6} xs={6} sm={6} className='centeritall'>        <button onClick={() => swiper.slideNext()}>Next</button></Grid>
        </Grid>
    

    </div>
  );
};

export default SwiperNavButtons;
