import React, { useState } from 'react';
// Import Swiper React components
import SwiperButton from './Swipbutton'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Grid } from '@mui/material'
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './styles.css';

// import required modules
import { Pagination, Navigation } from 'swiper/modules';

export default function App() {
    const [copiedAddress1, setCopiedAddress1] = useState(false);
    const [copiedAddress2, setCopiedAddress2] = useState(false);
  
    const handleCopyClick = (textToCopy, setAddressCopied) => {
      navigator.clipboard.writeText(textToCopy);
      setAddressCopied(true);
  
      // Reset the 'Copied' message after a short delay
      setTimeout(() => {
        setAddressCopied(false);
      }, 1500);
    };
  
    const address1ToCopy = '0xe5087395862a208071A7909687a6c4Fe30458F1e';
    const address2ToCopy = '0xA13bfC3C8A1a17549DCa6ff896501Fc5196515f0';
  return (
    <div className='slider'>
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
      
        navigation={true}
        modules={[Navigation]}
        className="mySwiper"
      >  
        <SwiperSlide>
            <Grid item lg={12} md={12} xs={12} sm={12} >
            <br/>    <br/>    <br/>  
<h2 className="info-title">Aztec Cryptocurrency Explainer Video</h2>
<br/>
       <div className="video-container">
                <iframe src="https://www.youtube.com/embed/DU-enErB-C4" frameborder="0" allowfullscreen=""></iframe>
            </div>


       </Grid>
       </SwiperSlide>
        <SwiperSlide>
        <Grid item lg={12} md={12} xs={12} sm={12} >
        <br/>    <br/>    <br/>  
<h2 className="info-title">Investment Strategy Video </h2>
<br/>


       <div className="video-container">
                <iframe src="https://www.youtube.com/embed/k8nd7fvNzPs" frameborder="0" allowfullscreen=""></iframe>
            </div>


       </Grid>

        </SwiperSlide>

        <SwiperSlide>

        <Grid item lg={12} md={12} xs={12} sm={12} >
        <br/>    <br/>    <br/>  
<h2 className="info-title">How To Buy</h2>
<br/>
       <div className="video-container">
                <iframe src="https://www.youtube.com/embed/iqSCYqp3ClA" frameborder="0" allowfullscreen=""></iframe>
            </div>
            <br/>
            
        
<p
        className='info-title_underc'
        style={{ textAlign: 'center', cursor: 'pointer' }}
     
      >
  
   
1. Go to buy/ sell page
<br/>
2. Sign Disclaimers<br/> 
3. Connect wallet<br/>
4. Input desired trade amount<br/>
5. Change the Max Slippage to account for liquidity.<br/>
6. Press Confirm
      </p>
<p
        className='info-title_underc'
        style={{ textAlign: 'center', cursor: 'pointer' }}
     
      >
     Note: It is important to adjust the max slippage before initiating the trade, please adjust the max slippage to around 30% if need be.

      </p>
  



       </Grid>

        </SwiperSlide>


        <SwiperSlide>

        <Grid item lg={12} md={12} xs={12} sm={12} >
        <br/>    <br/>    <br/>  
<h2 className="info-title">How To Sell </h2>
<br/>
       <div className="video-container">
                <iframe src="https://www.youtube.com/embed/vK9khk25ST4" frameborder="0" allowfullscreen=""></iframe>
            </div>

            <br/>
<p
        className='info-title_underc'
        style={{ textAlign: 'center', cursor: 'pointer' }}
     
      >
    Note: When selling tokens you must manually adjust the spending cap. The process is to adjust the spending cap is as follows;
    <br/>
1. Hit approve Aztec Tokens
<br/>
2. Hit Max Spending Cap <br/> 
3. Hit Next <br/>
4. Hit Confirm

      </p>
       </Grid>
        </SwiperSlide>

    
      <SwiperSlide>
        <Grid item lg={12} md={12} xs={12} sm={12} >
        <br/>    <br/>    <br/>  
    <h2 className="info-title">How To Import Tokens </h2>
    <br/>
           <div className="video-container">
                    <iframe src="https://www.youtube.com/embed/-_JbQpMfd_w" frameborder="0" allowfullscreen=""></iframe>
                </div>
                <br/>
    <p
            className='info-title_underc'
            style={{ textAlign: 'center', cursor: 'pointer' }}
         
          >
          Note: When importing your tokens, make sure that you switch to the polygon network.
    
          </p>
    
           </Grid>

          </SwiperSlide>
        <SwiperSlide>

        <Grid item lg={12} md={12} xs={12} sm={12} >
   
        <br/>    <br/>    <br/>  
   <h2 className="info-title">How To Stake Tokens </h2>
   
          <div className="video-container">
                   <iframe src="https://www.youtube.com/embed/b-ewac6KVH0" frameborder="0" allowfullscreen=""></iframe>
               </div>
  
       
   
   
   
   
          </Grid>
   

        </SwiperSlide>
      
      </Swiper>
    </div>
  );
}
