import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import YouTubeIcon from '@mui/icons-material/YouTube';
import { FaDiscord } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa6";
import Twitter from '@mui/icons-material/Twitter';
import { Grid } from '@mui/material';

import Roadmap from './Roadmap';
import About from './About';
import Faq from './Faq';
import art from './artm.png'
import top from './logoNorm.png'
import aztec from './aztecc.gif'
function Welcome() {
    useEffect(function () {
        Aos.init({ duration: 1000 });
      }, []);
  return (
    <div className=''>
        <br/> 
        <div className='container'>    <div className='centeritall'>
           
           <a href="https://www.youtube.com/@AztecCrypto?si=nKF2LpVlS6CZXQ00" target="_blank" className="youtube-icon">
               
               <YouTubeIcon  style={{fontSize:"35px" , position:"relative"  , bottom:"17px" , right:"19px"   }} />
               
   
               </a>
               <a href="https://www.facebook.com/profile.php?id=100088880297462&mibextid=LQQJ4d" target="_blank" className="youtube-icon">
               
               <FaFacebookF  style={{fontSize:"35px" , position:"relative"  , bottom:"17px" , right:"19px"   }} />
               
   
               </a>
             
               <a href="https://twitter.com/azteccryptoxyz?s=21&t=D9ZKCU4MRSaVbCAUgv5MWg" target="_blank" className="youtube-icon">
               
               <Twitter style={{fontSize:"35px" , position:"relative"  , bottom:"17px" , right:"19px"   }} />
               
               </a>
           
                   
           </div>
           
           <br/>
           <div data-aos="fade-right">   
              <p className='welcometop'>WELCOME TO</p></div>
           <img src={aztec} alt="Logo" style={{width:"100%"}} />
        
           <p className="sub-text">Backed by Aztec House Leveling &amp; Remodeling!</p>
   
   
           <div className='centeritall'>
               <a href='/info'>  <button className="learn-more-btn">Learn More</button></a>
               
              
             
           
                 
           </div>
           
           <About/>
           <br/><br/>
         <iframe
  title="QuickSwap"
  src="https://top100token.com/address/0xe5087395862a208071a7909687a6c4fe30458f1e"
  width="100%"
  height="600px"
  frameBorder="0"
  allowFullScreen
></iframe>
<br/><br/>
           
           

<h1 className="main-title_info">Who are we?</h1><br/>

<br/>        
        
          
        
        <div className="video-container">
       <iframe src="https://www.youtube.com/embed/eQ2h_8cGOkg" frameborder="0" allowfullscreen=""></iframe>
   </div><br/>
   <br/>


<h1 className="main-title_info">WHAT DO WE OFFER?</h1><br/><br/>


        <div className='centeritall'>
        
            
           
          
        
                 <div className="video-container">
                <iframe src="https://www.youtube.com/embed/k8nd7fvNzPs" frameborder="0" allowfullscreen=""></iframe>
            </div>
        </div>
        <br/>
        <div className='centeritall'>
           
            
   
      
          
  
        
             
        </div> <br/>
        <h1 className='herotitleu'>
            <br />
            <span style={{ color: "#9f8cf7" }} className='neonText'> limited
 SUPPLY</span>
          </h1>
    <br/>
    <h2 class="about-aztec-title" style={{textAlign:"center"}}>21,000,000     <br/>Aztec Tokens</h2>
        <br/>

<h2 className="tophead_info">Token Value increases from revenue streams.</h2>
<br/><br/>
           </div>
    
      


   
      

 {/* <Grid container spacing={2}>
        <Grid item md={6} lg={6} xs={12} sm={12}>
        <div data-aos="fade-right"> <div className="about-logo">
                <img src={Logo} alt="Logo" />
            </div></div>
       

        </Grid>

<Grid item md={6} lg={6} xs={12} sm={12} className='centeronmobile'>

<br/>
<h3 className="about-title">Why Buy Aztec Cryptocurrency?</h3>


<br/>
<ul class="about-list">
    <li>Play Games</li>
                    <li>Non-Fiat Currency</li>
                  
                    <li> Stake Your Tokens</li>
                    <li>Cash-Out Anytime</li>
                 
                </ul>
</Grid>


</Grid>*/}
     
<Grid container spacing={2}>
        <Grid item md={12} lg={12} xs={12} sm={12}>
        <div data-aos="fade-right"> <div className="">
                <img src={art} alt="Logo" style={{width:"100%"}} />
            </div></div>
        </Grid>
</Grid>
<div className="container"><Roadmap />
<Faq/>

<Grid container spacing={2}>
        <Grid item md={12} lg={12} xs={12} sm={12}>
      <a href="https://top100token.com/address/0xe5087395862a208071a7909687a6c4fe30458f1e" target="_blank"> <div data-aos="fade-right"> <div className="about-logo">
                <img src={top} alt="Logo" style={{width:"100%"}} />
            </div></div></a> 
        </Grid>
</Grid>


<br/><br/>
</div>




        </div>
  )
}

export default Welcome