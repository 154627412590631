import React from 'react'

function BuySell() {
  return (
    <div className='container'>

<div ><br/><br/><br/>
      <iframe
        title="QuickSwap"
        src="https://quickswap.exchange/#/swap?inputCurrency=ETH&outputCurrency=0xe5087395862a208071A7909687a6c4Fe30458F1e&swapIndex=1"
        width="100%"
        height="600px"
        frameBorder="0"
        allowFullScreen
      ></iframe>
    </div>
    <br/><br/><br/><br/>


    </div>
  )
}

export default BuySell