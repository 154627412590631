import React from 'react'
import Logo from './Logo.gif'
import Money from './icon/money.gif'
import Dance from './icon/gamsl.gif'
import Nft from './icon/nfts.gif'
import Mexico from './icon/msho.gif'
import Rocket from './icon/rocket.gif'


function Roadmap() {
  return (
    <div className='centeritall'>
<br/>
<h2 className="roadmap-title">THE ROADMAP</h2>

<br/><br/>


<div className="roadmap-icons">
  
                <div className="icon-container">
                <div data-aos="fade-top"> <img src={Logo} alt="Placeholder"/></div>
                   
                    <p>Generate the perfect token</p>
                </div>
                <div className="icon-container">
                <div data-aos="fade-top">      <img src={Money} alt="Placeholder"/></div>
          
                    <p>DEVELOP OWN STAKING DAPP</p>
                </div>
                <div className="icon-container">
                <div data-aos="fade-top">         <img src={Dance} alt="Placeholder"/></div>
       
                    <p>Gaming</p>
                </div>
             
                <div className="icon-container">
                <div data-aos="fade-top">     <img src={Nft} alt="Placeholder"/></div>
           
                    <p>NFT'S</p>
                </div>
                <div className="icon-container">
                <div data-aos="fade-top">       <img src={Mexico} alt="Placeholder" className='msho'/></div>
         <br/>
                    <p>Merch Shop</p>
                </div>
               
                <div className="icon-container">
                <div data-aos="fade-top">       <img src={Rocket} alt="Placeholder"/></div>
         
                    <p>TO THE MOON!</p>
                </div>
            </div>

<br/>     
    </div>
  )
}

export default Roadmap