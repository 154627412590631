import React from 'react'
import Contacts from './contacto.png'
import Form from './Form.jsx'
import { Grid } from '@mui/material'
function Contactus() {
  return (
    <div className='container'>
        <br/>  <br/>
      <img src={Contacts} style={{width:"100%"}}/>
        <br/> 
        <Grid container spacing={2}>
        <Grid item md={3} lg={3} xs={12} sm={12}>

            
</Grid>
<Grid item md={6} lg={6} xs={12} sm={12}>
<Form/>
            
</Grid>
<Grid item md={3} lg={3} xs={12} sm={12}>

            
</Grid>
        </Grid>
  
         <br/> <br/>
         <h6> If you are a Web3 Pioneer and would like to be part of the team, or if you just have an idea in the crypto space that you want to pursue, contact us! We would love to hear from you to see how we, AztecCrypto.XYZ, can help facilitate any projects. We would love to partner with you, so reach out today!</h6>
         <br/>  <br/> <br/>
        </div>
  )
}

export default Contactus