import React from 'react'

function Disclaimer() {
  return (
    <div className='container'>
        
        <section>  <br/>  <br/>  <br/>   
      <h2 className='headtext_footer_pages'>Aztec Crypto: A Revolutionary Investment in the Aztec Tokens</h2>
      <br/>
      <p style={{textAlign:"center" , fontSize:"13px"}}>
      Are you looking for a new way to invest your money in the Rio Grande Valley? Look no further than Aztec Crypto Coin! Our coin is tailor-made for the Aztec Tokens, providing a range of benefits for those who choose to invest.
      </p>
      <br/>   
      <h2 className='headtext_footer_pages'>A Store of Value</h2>
      <br/>
      <p style={{textAlign:"center" , fontSize:"13px"}}>
      One of the biggest benefits of Aztec Crypto Coin is that it can be used as a store of value. Unlike traditional currencies that are subject to inflation and other economic factors, our coin is protected by blockchain technology, ensuring the stability of your investment.
      </p>
      <br/>   
      <h2 className='headtext_footer_pages'>Passive Income</h2>
      <br/>
      <p style={{textAlign:"center" , fontSize:"13px"}}>
      Another advantage of Aztec Crypto Coin is that it generates passive income. By holding our coin, you become eligible for regular payouts, which can contribute to your financial stability and growth.
      </p>
      <br/>   
      <h2 className='headtext_footer_pages'>The Future of Finance</h2>
      <br/>
      <p style={{textAlign:"center" , fontSize:"13px"}}>
      At Aztec Crypto, we believe that our coin represents the future of finance in the RGV. Embrace the revolution and take control of your financial future by investing in Aztec Crypto Coin today!
      </p>
      <br/>
      
<div className='centeritall'>
<a href='news'>
<button class="cybr-btn">
  Back<span aria-hidden></span>
  <span aria-hidden class="cybr-btn__glitch">Aztec</span>


</button>

</a>

<br/>    <br/> 
</div>
   


      
    </section>
        
        
        
        </div>
  )
}

export default Disclaimer