import React from 'react'

function Disclaimer() {
  return (
    <div className='container'>
        
        <section>  <br/>  <br/>  <br/>   
      <h2 className='headtext_footer_pages'>Aztec Crypto Disclaimer</h2>
      <br/>
      <p style={{textAlign:"center" , fontSize:"13px"}}>The information provided on this website is for general informational purposes only. Aztec Crypto does not provide financial, investment, or legal advice.</p>
      <br/>
      <h2 className='headtext_footer_pages'>Accuracy of Information</h2>
      <br/>
      <p style={{textAlign:"center" , fontSize:"13px"}}>We strive to ensure that the information provided is accurate and up-to-date, but we make no warranties or guarantees regarding its accuracy, completeness, or suitability.</p>
      <br/>
      <h2 className='headtext_footer_pages'>Use of Our Services</h2>
      <br/> 
      <p style={{textAlign:"center" , fontSize:"13px"}}>Users should conduct their own research and consult with professionals before making any financial or investment decisions.</p>
      <br/> 

<div className='centeritall'>
<a href='welcome'>
<button class="cybr-btn">
  Back<span aria-hidden></span>
  <span aria-hidden class="cybr-btn__glitch">Aztec</span>


</button>

</a>


</div>
   


      
    </section>
        
        
        
        </div>
  )
}

export default Disclaimer