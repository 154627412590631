import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";

import Home from "./pages/HomeX";
import Welcome from "./pages/Welcome";
import Disclaimer from "./pages/Disclaimer";
import PrivacyPolicy from './pages/PrivacyPolicy'
import TermCondition from './pages/TermsConditions'
import News from './pages/News'
import Info from './pages/Info'
import Whitepaper from './pages/WhitePaper'
import Team from './pages/Team'
import Blog1 from './pages/RecentBlog_one'
import Blog2 from './pages/RecentBlog_two'
import Blog3 from './pages/RecentBlog_three'
import Resource from './pages/Resource'
import BuySell from "./pages/BuySell";
import Staking from './pages/StakingPage'
import Gaming from './pages/Gaming'
import Contactus from "./pages/ContactUs";

import './Aztec.scss'
import theme from "./Theme";
import "rsuite/styles/index.less";
import "./App.css";
import "./style.scss";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate loading delay (you can replace this with actual data fetching or any async operation)
    const timeout = setTimeout(() => {
      setLoading(false); // Set loading to false after the timeout
    },3000); // Replace 2000 with the desired delay in milliseconds

    // Clean up the timeout on unmount or when loading is done
    return () => clearTimeout(timeout);
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>AZTEC Crypto Currency</title>
        <meta name="Aztec Crypto Currency" content="Aztec Crypto Currency" />
      </Helmet>
      {loading ? (
        // Show loading animation while loading is true
        <div style={{backgroundColor:"#000"}}><div class="containerz">
        <div id='loader'>
          <div id='title' class='flex'>
            <p class='loading-text'>LOADING AZTEC Crypto Currency</p>
            <div class='therefore'>∴</div>
            <p class="loading-number">%</p>
          </div>
          <div id='loading-bar-border'>
            <div class='loading-bar'>
            </div>
          </div>
          <div id='warning'>
            <p>
              <div class='exclamation'>!</div>
              CAUTION, Do not turn off.
            </p>
          <div id='line-cascates'></div>
          </div>
        </div>
      </div></div>
      ) : (
        // Render your main content once loading is false
        <Router>
          <Routes>
            <>
            <Route path="/" exact={true} element={<Home />} />
            <Route path="welcome" exact={true} element={<Welcome />} />
  
            <Route path="staking" exact={true} element={ <Staking/>} />
            <Route path="buy-sell" exact={true} element={ <BuySell/>} />
            <Route path="resources" exact={true} element={ <Resource/>} />
            <Route path="recentblog_one" exact={true} element={ <Blog1/>} />
            <Route path="recentblog_two" exact={true} element={ <Blog2/>} />
            <Route path="recentblog_three" exact={true} element={ <Blog3/>} />
            <Route path="team" exact={true} element={ <Team/>} />
            <Route path="Whitepaper" exact={true} element={ <Whitepaper/>} />
            <Route path="Info" exact={true} element={ <Info/>} />
            <Route path="News" exact={true} element={<News />} />
            <Route path="Term_Condition" exact={true} element={<TermCondition />} />
            <Route path="PrivacyPolicy" exact={true} element={<PrivacyPolicy />} />
            <Route path="disclaimer" exact={true} element={<Disclaimer />} />
            <Route path="Contact" exact={true} element={<Contactus/>} />
          
            </>
          </Routes>
        </Router>
      )}
    </ThemeProvider>
  );
}

export default App;
